.button-container {
    max-width: 180px;
    width: 100%;
    grid-column: auto / span 1;
    grid-row: 1 / span 1;
    margin-left: auto;
    margin-right: auto;
}

.button-container button {
    width: 100%;
    background: #FFC80C;
    color: #000000;
    font-size: 1rem;
    font-weight: 700;
    width: 100%;
    border: 1px solid #edf3f5;
    border-radius: 4px;
    padding: 1em;
    cursor: pointer;
    margin-top: 0.625rem;
    max-width: 180px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 16px 30px rgb(23 31 114 / 20%);
  
    &:hover,
    &:active,
    &:focus {
      color: #000000;
      border: 1px solid #FFC80C;
      background-color: #FFFFFF;
    }
}
.footer-wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 1em;
}
@media screen and (max-width: 600px) {
    .footer-wrapper {
        grid-template-columns: 1fr;
    }
  }

.footer-wrapper .element {
    grid-column: auto / span 1;
    grid-row: auto;
}

.footer-wrapper p {
    font-size: medium;
}

.footer-wrapper h1 {
    font-size: large;
    font-weight: lighter;
}
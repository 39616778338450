.datenschutz-wrapper {
    max-width: 1200px;
    width: 100%;
    margin: auto;
}

.datenschutz-wrapper h1 {
    font-size: xx-large;
}

.datenschutz-wrapper h2 {
    font-size: x-large;
}

.datenschutz-wrapper h3 {
    font-size: larger;
}

.datenschutz-wrapper h4 {
    font-size: larger;
}

.datenschutz-wrapper p {
    font-size: large;
}
.img-container {
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
}

.img-container img {
    margin-left: auto;
    margin-right: auto;
}

.img-container svg {
    margin: auto;
}

.img-container img:hover {
    transform: scale(0.99);
    transition: transform 200ms;
}